

.Quotation-container {
    width: 100%;
    height: auto;
    // background: url('../Img/Background/wave5.svg');
}

.Quotation-slider {
    width: 100%;
    height: 45vh;
    // border: 1px solid black;
}

.Quotation-slider-container {
    height: 100%;
    width: 100%;
    // border: 1px solid black;
    background-image: linear-gradient(rgba(36, 160, 227, 0.6), rgba(6, 58, 86, 0.6)),
                      url('../Img/quotation.jpg');
    background-size: cover;       
    background-position: center center;  
    background-repeat: no-repeat; 
    text-align: center;
    // position: relative;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

.Quotation-slider-text {
    h4 {
        margin-top: 3em;
        color: black;
        margin-bottom: 50px;
    }
    h2 {
        margin-top: 50px;
        color: black;
        margin-top: -30px;
    }
    p {
        color:white;
        font-size: 20px;
    }
}

.quotation-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.quotation-form {
    width: 75%; /* Makes the form 75% of the screen width */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    form {
        display: flex;
        flex-direction: column;
        gap: 15px; /* Adds space between form elements */
    }

    label {
        font-weight: bold;
        margin-bottom: -7px;
    }

    input,
    select {
        font-size: 18px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 15px;
        box-sizing: border-box;
        margin-bottom: 10px;
        height: 47px;

        &:focus {
            outline: none; /* Removes default outline on focus */
            border-color: #007bff; /* Change border color on focus */
        }

        &::placeholder {
            color: #999; /* Placeholder color */
        }
        
    }

    // input[type="tel"] {
    //     /* Styles specifically for the contact number input */
    //     max-width: 100%;
    // }

    textarea {
        width: 100%;
        height: 10em; /* Sets height of textarea */
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 15px;
        box-sizing: border-box;
        margin-bottom: 10px;
        resize: vertical; /* Allows resizing vertically */
        font-size: 18px;
        color: #000;
    }

    button {
        padding: 14px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: large;

        &:hover {
            background-color: #0056b3;
        }

    }
}



/* Checkbox Group Styling */
.checkbox-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns layout */
    gap: 15px; /* Space between the columns */
    padding: 15px;
    border-radius: 8px;
    // background-color: #f9f9f9; /* Light gray background for the checkbox group */
}

.checkbox-option {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
        width: 20px; /* Set size of checkbox */
        height: 20px;
        margin-right: 10px; /* Space between checkbox and label */
        cursor: pointer;
        accent-color: #007bff; /* Change checkbox tick color */
    }

    label {
        font-size: 16px; /* Increase font size for labels */
        font-weight: bold;
        color: #333; /* Darker color for the text */
    }
}

.thank-you-message {
    text-align: center;
    padding: 20px;
    // background-color: #f9f9f9;
    // border-radius: 8px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h2 {
        color: #007bff;
    }

    p {
        margin-bottom: 20px;
        
    }

    a {
        color: #007bff;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .quotation-form {
        width: 90%; /* Adjust form width for smaller screens */
    }
}

@media (max-width: 480px) {
    .Quotation-slider-text h4 {
        font-size: 1.5em;
    }

    .Quotation-slider-text h2 {
        font-size: 1.8em;
    }

    .Quotation-slider-text p {
        font-size: 16px;
    }

    .quotation-form-container {
        padding: 10px;
    }

    .quotation-form {
        width: 100%;
        padding: 10px;
    }

    .checkbox-group {
        grid-template-columns: 1fr; /* Stack checkboxes in a single column */
    }
}
/* Back to Top Button */
.scrollToTop {
    position: fixed;
    bottom: 20px; /* Distance from the bottom */
    right: 20px;  /* Distance from the right */
    width: 40px;  /* Width of the button */
    height: 40px; /* Height of the button */
    background-color: #333; /* Dark background color */
    color: white; /* Text color */
    border-radius: 50%; /* Circular shape */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
    font-size: 18px; /* Font size of the icon */
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    z-index: 1000; /* Ensure it is above other content */
  
    &:hover {
      background-color: #555; /* Slightly lighter background on hover */
      transform: scale(1.1); /* Slightly increase the size on hover */
    }

    span {
      font-weight: bold; /* Make the arrow bold */
    }
}
