$mainColor: #05a0e3;

nav {
    overflow-x: hidden;
    padding: 1.5em 0;

    .nav-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // .logo {
        //     background-color: #45c3e6; // Temporary background color
        //     max-width: 200px;
        //     max-height: 200px;
        // }

        // .logo {
        //     max-width: 80px; // Set a max-width for the logo container
        //     max-height: 50px;
        //     // display: flex;
        //     align-items: center;

        //     .logo-image {
        //         max-width: 100%; // Ensure the image scales within the container
               
        //         display: block; // Prevent overflow issues
        //     }
        // }

        
    

        .menu {
            ul {
                list-style: none;
                padding-left: 0;
                margin: 0;

                li {
                    display: inline-block;
                    margin: 0 1.5em;
                    color: black;
                    font-weight: 400;

                    a {
                        color: black;
                        text-decoration: none;
                        position: relative;
                        transition: color 0.1s ease-in-out;

                        &.active {
                            color: $mainColor;
                        }

                        &:after {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            bottom: -5px;
                            background-color: $mainColor;
                            width: 0;
                            height: 2px;
                            transition: width 0.1s ease-in-out;
                        }

                        &:hover:after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .language {
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: color 0.1s ease-in-out;

            .globe {
                color: lighten(black, 70%);
            }

            .angleDown {
                font-size: 0.6em;
            }

            &:hover {
                color: black;
                cursor: pointer;
            }

            p {
                margin: 0 0.2em;
                color: black;
            }
        }

        .quotation {
            .activeQuotation {
                color: white;
                background-color: $mainColor;

                &::after {
                    display: none;
                }
            }

            a {
                border: 0.12em solid $mainColor;
                padding: 0.35em 1em;
                color: $mainColor;
                text-decoration: none;
                background-color: white;
                border-radius: 0.2em;
                transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;

                &:hover {
                    background-color: $mainColor;
                    color: white;
                }
            }
        }

        .mobile-menu-icon {
            display: none;
        }
    }

    // Media queries for responsiveness
    @media screen and (max-width: 1100px) {
        .nav-items {
            justify-content: space-between;
            margin-bottom: -13px;
            margin-top: -3px;
            // margin-left: -10px;

            .menu,
            .language,
            .quotation {
                display: none; // Hide menu items for mobile view
                
            }
            

            .logo {
                max-width: 100px; // Adjust logo size for mobile view
                margin-left: -10px;
            }

            .mobile-menu-icon {
                display: block;
                color: $mainColor;
                font-size: 2em;
                cursor: pointer;
            }
        }
    }
}



/* gpt things */
nav {
    overflow-x: hidden;
    padding: 1.5em 0;

    .nav-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .logo {
            max-width: 80px; // Set a max-width for the logo container
            max-height: 50px; // Set a max-height for the logo container
            
            .logo-image {
                max-width: 100%; // Make sure the image fits within the container
                max-height: 100%; // Ensure it scales properly
                display: block; // Prevent overflow issues
                margin-top: -33px;
                margin-bottom: -32px;
            }
        }


        .language {
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: color 0.1s ease-in-out;

            .globe {
                color: lighten(black, 70%);
            }

            .angleDown {
                font-size: 0.6em;
            }

            &:hover {
                color: black;
                cursor: pointer;
            }

            p {
                margin: 0 0.2em;
                color: black;
            }
        }

        .quotation {
            .activeQuotation {
                color: white;
                background-color: $mainColor;

                &::after {
                    display: none;
                }
            }

            a {
                border: 0.12em solid $mainColor;
                padding: 0.35em 1em;
                color: $mainColor;
                text-decoration: none;
                background-color: white;
                border-radius: 0.2em;
                transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;

                &:hover {
                    background-color: $mainColor;
                    color: white;
                }
            }
        }

        .mobile-menu-icon {
            display: none;
        }
    }

    // Media queries for responsiveness
    @media screen and (max-width: 1100px) {
        .nav-items {
            justify-content: space-between;

            .menu,
            .language,
            .quotation {
                display: none; // Hide menu items for mobile view
            }

            .logo {
                max-width: 70px; // Adjust logo size for mobile view
                // margin-bottom: -30px;
                // margin-top: -20px;
            }

            .mobile-menu-icon {
                display: block;
                color: $mainColor;
                font-size: 2em;
                cursor: pointer;
            }
        }
    }
}
