$mainColor : #05a0e3;

.footer{
    background-color: #294956;
    color: white; /* Default text color white for all text inside .footer */
    margin-top: 14em;

    iframe{
        width: 100%;
        height: 300px;
        display: block;
        margin: 0 auto;
        border-radius: .4em;
        transform: translateY(-50%);
    }

    .column-wrapper{
        display: flex;
        gap:0 1em;
        flex-wrap: wrap;
        margin-top: -6em;
        justify-content: space-between;

        .column{
            margin:0 0.5em;

            h5{
                font-size: 1.1em;
                margin-bottom: 1em;
                color: white; /* Ensure h5 headers are white */
            }

            a{
                text-decoration: none;
                color:white; /* Make links white */
                display:block;
                margin: .5em 0;

                &:hover{
                    text-decoration: underline;
                }
            }

            p{
                line-height: 1em;
                margin: .7em 0;
                font-size: .9em;
                color: white; /* Paragraphs in white */
            }
        }
    }

    .copyright{
        display: flex;
        margin-top: 4em;
        justify-content: space-between;

        p{
            font-size: 1.1em;
            color: white; /* Make copyright text white */
        }

        p:first-of-type{
            font-weight: 400;
        }

        .back-to-top{
            display: flex;
            flex-direction: row;
            align-items: center;

            p:hover{
                cursor: pointer;
            }
        }

        #backToTop{
            width: 30px;
            height: 30px;
            stroke:$mainColor;
            fill:$mainColor;
            stroke-width:15;
            margin-right: 1em;

            &:hover{
                cursor: pointer;
            }
        }
    }
}

/* Media queries */
@media screen and (max-width:1100px){
    .footer{
        .copyright{
            justify-content: center;
            margin: 0;
        }
    
        .back-to-top{
            display: none !important;
        }
    }
}

@media screen and (max-width:425px){
    .footer{
        .column-wrapper{
            flex-direction: column;

            .column{
                width: 100%;
                margin: 0;

                .h5{
                    margin-top: 0 !important;
                }
            }
        }
    }
}
