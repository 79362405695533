// /*General*/
// $mainColor : #05a0e3;

// html{
//     font-size: 16px;
// }

// html,body{
//     padding: 0;
//     margin: 0;
// }

// body{
//     font-family: 'Roboto', sans-serif;
// }

// .container{
//     width: 80%;
//     margin: 0 auto;
//     max-width: 1300px;
// }

// .container-inner{
//     width: 90%;
//     margin: 0 auto;
// }

// .wrapper{
//     overflow-x: hidden;
// }

// .section{
//     padding: 3em 0;
// }

// /*scroll to top icon*/
// .scrollToTop{
//     position: fixed;
//     bottom: 2em;
//     right: 2em;
//     width: 2em;
//     height: 2em;
//     fill: white;
//     padding: .5em;
//     border-radius: .4em;
//     background-color: $mainColor;
//     stroke: white;
//     stroke-width:1;
//     z-index: 10;

//     &:hover{
//         cursor: pointer;
//     }
// }

// /*Headings and paragraphs*/
// h1{
//     font-size: 3em;
// }

// h2{
//     font-size:2.5em;
// }

// h3{
//     font-size: 2em;
    
// }

// h4{
//     font-size: 1.5em;
// }

// h5{
//     font-size: 1em;
// }

// p{
//     line-height: 1.5em;
// }

// /*Icons*/
// #dots{
//     position: absolute;
//     fill: $mainColor;
//     opacity: 50%;
//     top: 0px;
//     left: -9px;
//     width: 30px;
// }

// h2{
//     position: relative;
//     z-index: 2;

//     #dots{
//         z-index: -1;
//     }
// }

// #square,#circle{
//     fill:none;
//     stroke:$mainColor;
//     stroke-width: .6;
//     position: absolute;
//     z-index: -1;
// }
  
// /*Views Transitions*/
// /*Slide forward*/
// .slide-forward-enter{
//     opacity: 0;
//     transform: translateX(100%);
// }
  
// .slide-forward-enter-active{
//     opacity: 1;
//     transition: 0.3s ease-in-out;
//     transform: translateX(0);
// }
  
// .slide-forward-exit{
//     opacity: 1;
//     transform: translateX(0);
// }
  
// .slide-forward-exit-active{
//     opacity: 0;
//     transition: 0.3s ease-in-out;
//     transform: translateX(-100%);
// }

// /*Slide backward*/
// .slide-backward-enter{
//     opacity: 0;
//     transform: translateX(-100%);
// }
  
// .slide-backward-enter-active{
//     opacity: 1;
//     transition: 0.3s ease-in-out;
//     transform: translateX(0);
// }
  
// .slide-backward-exit{
//     opacity: 1;
//     transform: translateX(0);
// }
  
// .slide-backward-exit-active{
//     opacity: 0;
//     transition: 0.3s ease-in-out;
//     transform: translateX(-100%);
// }

// /*scroll to top transitions*/
// .showScrollToTop-enter{
//     transform: translateX(200%);
// }

// .showScrollToTop-enter-active{
//     transform: translateX(0%);
//     transition: .3s ease-in-out;
// }

// .showScrollToTop-exit{
//     transform: translateX(0%);
// }

// .showScrollToTop-exit-active{
//     transform: translateX(200%);
//     transition: .3s ease-in-out;
// }


// /*Media queries*/
// @media screen and (max-width: 1100px){
//     .section{
//         padding: 2em 0;
//     }
// }

// @media screen and (max-width: 830px){
//     html{
//         font-size: 14px;
//     }
    
//     .container-inner{
// 		width:100%;
// 	}
// }

// .scroll-to-top {
//     position: fixed;
//     bottom: 20px; /* Distance from the bottom */
//     right: 20px;  /* Distance from the right */
//     width: 60px;  /* Width of the button */
//     height: 60px; /* Height of the button */
//     background-color: #007bff; /* Background color */
//     color: white; /* Text color */
//     border-radius: 50%; /* Circular shape */
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow effect */
//     font-size: 24px; /* Font size of the arrow */
//     text-align: center;
//     transition: background-color 0.3s ease, transform 0.3s ease;
  
//     &:hover {
//       background-color: #0056b3; /* Darker background on hover */
//       transform: scale(1.1); /* Slightly increase the size on hover */
//     }
  
//     span {
//       font-weight: bold; /* Make the arrow bold */
//     }
//   }
  



/* General */
$mainColor: #05a0e3;

html {
    font-size: 16px;
}

html, body {
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Roboto', sans-serif;
}

.container {
    width: 80%;
    margin: 0 auto;
    max-width: 1300px;
}

.container-inner {
    width: 90%;
    margin: 0 auto;
}

.wrapper {
    overflow-x: hidden;
}

.section {
    padding: 3em 0;
}

/* Headings and paragraphs */
h1 {
    font-size: 3em;
}

h2 {
    font-size: 2.5em;
}

h3 {
    font-size: 2em;
}

h4 {
    font-size: 1.5em;
}

h5 {
    font-size: 1em;
}

p {
    line-height: 1.5em;
}

/* Icons */
#dots {
    position: absolute;
    fill: $mainColor;
    opacity: 50%;
    top: 0px;
    left: -9px;
    width: 30px;
}

h2 {
    position: relative;
    z-index: 2;

    #dots {
        z-index: -1;
    }
}

#square, #circle {
    fill: none;
    stroke: $mainColor;
    stroke-width: .6;
    position: absolute;
    z-index: -1;
}
  
/* Views Transitions */
/* Slide forward */
.slide-forward-enter {
    opacity: 0;
    transform: translateX(100%);
}

.slide-forward-enter-active {
    opacity: 1;
    transition: 0.3s ease-in-out;
    transform: translateX(0);
}

.slide-forward-exit {
    opacity: 1;
    transform: translateX(0);
}

.slide-forward-exit-active {
    opacity: 0;
    transition: 0.3s ease-in-out;
    transform: translateX(-100%);
}

/* Slide backward */
.slide-backward-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.slide-backward-enter-active {
    opacity: 1;
    transition: 0.3s ease-in-out;
    transform: translateX(0);
}

.slide-backward-exit {
    opacity: 1;
    transform: translateX(0);
}

.slide-backward-exit-active {
    opacity: 0;
    transition: 0.3s ease-in-out;
    transform: translateX(-100%);
}

/* Media queries */
@media screen and (max-width: 1100px) {
    .section {
        padding: 2em 0;
    }
}

@media screen and (max-width: 830px) {
    html {
        font-size: 14px;
    }

    .container-inner {
        width: 100%;
    }
}


/* Back to Top Button */
.back-to-top {
    position: fixed;
    bottom: 20px; /* Distance from the bottom */
    right: 20px;  /* Distance from the right */
    width: 70px;  /* Width of the button */
    height: 70px; /* Height of the button */
    background-color: #05a0e3; /* Main color */
    color: white; /* Text color */
    border-radius: 50%; /* Circular shape */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Elevated shadow effect */
    font-size: 28px; /* Font size of the icon */
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      background-color: #0383c7; /* Darker background on hover */
      transform: scale(1.1); /* Slightly increase the size on hover */
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4); /* Darker shadow on hover */
    }
  
    svg {
      fill: white; /* Icon color */
    }
  }
  