$mainColor : #05a0e3;
/*Mobile menu*/
.mobileMenu{
    .container{
        width: 100%;
        padding: 2em;
        box-sizing: border-box;

        h4{
            margin-top: 0;
        }

        ul{
            margin: 0;
            padding-left: 0;
    
            li{
                list-style: none;
                
                a{
                    color:black;
                    text-decoration: none;
                    font-size: 1.2em;
                    margin-top: 1em;
                    display:block;
                }
            }
        }
    }
}

.mobile-menu-wrapper{
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    height: 100%;
    width: 75%;
    z-index: 15;
}

.mobile-menu-overlay{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
}

/*Transitions*/
/*Menu*/
.mobile-navigation-enter{
    opacity:0;
    transform: translateX(-100%);
}

.mobile-navigation-enter-active{
    opacity: 1;
    transform: translateX(0);
    transition: .3s ease-in-out;
}

.mobile-navigation-exit{
    opacity: 1;
    transform: translateX(0);
}

.mobile-navigation-exit-active{
    opacity: 1;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
}

/*Overlay*/
.mobile-navigation-overlay-enter{
    opacity:0;
}

.mobile-navigation-overlay-enter-active{
    opacity: 1;
    transition: .3s ease-in-out;
}

.mobile-navigation-overlay-exit{
    opacity: 1;
}

.mobile-navigation-overlay-exit-active{
    opacity: 0;
    transition: .3s ease-in-out;
}

@media screen and (max-width:1100px){
    .mobileMenu{
        h4{
            font-size: 2em;
        }

        ul{
            font-size: 1.5em;
        }
    }
}

@media screen and (max-width:500px){
    .mobileMenu{
        h4{
            font-size: 1.7em;
        }

        ul{
            font-size: 1.2em;
        }
    }
}
.logo_comp{
    height: 50px;
    width: 70px;
    margin-top: -20px;
}