/* Default styles */
.sidebar-container {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 8px 8px 0;
  transition: transform 0.6s ease-in-out;
  transform: translateX(0);
}

.sidebar.hidden {
  transform: translateX(-100%);
}

.sidebar a {
  padding: 10px;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  border-bottom: 1px solid #444;
  transition: background 0.3s ease;
}

.sidebar a:hover {
  background: #555;
}

.toggle-btn {
  background: grey;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.toggle-btn:hover {
  background: #000;
}

/* Rotate the "Contact us" button */
.sidebar a:first-of-type {
  background: red;
  writing-mode: vertical-lr;
  text-align: center;
  height: 120px;
  align-items: center;
  transform: rotate(180deg);
}

.icon-envelope {
  font-size: 1.5em;
  color: white;
  transform: rotate(90deg);
  margin-bottom: 5px;
}

.sidebar a:nth-of-type(2) {
  background: #25d366;
}

.sidebar a:nth-of-type(3) {
  background: #0077b5;
}

.icon-whatsapp, .icon-linkedin {
  font-size: 1.5em;
  color: white;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 35px; /* Decrease sidebar width */
  }
  
  .sidebar a {
    padding: 8px; /* Adjust padding for smaller buttons */
  }

  .sidebar a:first-of-type {
    height: 110px; /* Adjust height of the "Contact us" button */
  }
  
  .icon-envelope, .icon-whatsapp, .icon-linkedin {
    font-size: 1.2em; /* Decrease icon size */
  }

  .toggle-btn {
    padding: 8px; /* Adjust toggle button size */
  }
}
