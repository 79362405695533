$mainColor : #05a0e3;

.slider-main{
    width: 100%;

    .slide-1,.slide-2,.slide-3, .slide-4{
        width: 100%;
        height: 710px;
        position: relative;
        background-size: cover; /* Ensures the image covers the div */
        background-position: center; /* Centers the image */
        outline: none;
        overflow: hidden;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
        
        
    }



    // .slide-1, .slide-2, .slide-3, .slide-4 {
    //     width: 100%;
    //     height: 710px;
    //     position: relative;
    //     outline: none;
    //     overflow: hidden; /* Ensures no overflow outside the div */
    //     transition: opacity 0.5s ease-in-out; /* Smooth transition for fading effect */
    //     opacity: 0; /* Initially, set the opacity to 0 */
    // }
    
    
    
    
        .text{
            position: absolute;
            top: 20%;
            left: 8%;

            p{
                font-size: 2.5em;
                margin-bottom: 0;
                color: white;

            }

            h1{
                margin-top:0;
                color:rgb(0, 132, 255);
                font-size: 6em;
            }
        }
    }

    .slide-1{
        background:linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),url('../Img/Carousel-Main/slide-1.jpg') center center;
    }

    .slide-2{
        background:linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),url('../Img/Carousel-Main/slide-3.jpg') center center;
    }

    .slide-3{
        background:linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),url('../Img/Carousel-Main/slide-2.jpg') center center;
    }
    
    .slide-4{
        background:linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),url('../Img/Carousel-Main/2nd.jpg') center center;
    }
    .slick-dots{
        bottom: 15%;

        li{
            margin: 0 1em;
        }
        

        li button:before{
            font-size: 20px;
            color:white;
            opacity: 1;
            content: '▬▬';
        }
    
        .slick-active{
            button::before{
                color:$mainColor !important;
                opacity: 1 !important;
            }
        }
    }

    /*Media queries - MOBILE*/
    @media screen and (max-width:500px){
        .slide-1,.slide-2,.slide-3, .slide-4,.slide-5{
            height: 400px !important;
            background-size: cover;

            .text{
                top: 20%;
                left: 5%;
                

                h1{
                    font-size: 4em !important;
                }
                
            }

        }

        .slick-dots{
            bottom: 5%;
        }

    }


