.client-container {
    width: 100%;
    height: auto;
}

.client-slider {
    width: 100%;
    height: 45vh;

    @media (max-width: 768px) {
        height: 35vh;  // Adjust height for tablets
    }

    @media (max-width: 480px) {
        height: 30vh;  // Adjust height for mobile devices
    }
}

.client-slider-container {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(87, 162, 202, 0.6), rgba(2, 30, 45, 0.6)), url('../Img/client/client.avif');
    background-size: cover;
    background-position: center center;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        background-size: contain;  // Adjust background image for tablets
        background-repeat: repeat; // Repeat the background image for tablets
    }

    @media (max-width: 480px) {
        background-size: contain;  // Adjust background image for mobile
        padding: 10px;  // Add padding for small screens
    }
}

.client-slider-text {
    position: relative;
    display: inline-block;
    text-align: center; // Ensure text is centered

    h2 {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        color: black;
        font-size: 36px;
        margin-bottom: -5px;
    

        @media (max-width: 768px) {
            font-size: 24px;  // Adjust font size for tablets
        }

        @media (max-width: 480px) {
            font-size: 17px;  // Adjust font size for mobile
            margin-left: -14px;
            // font-weight: 500;
        }
    }

    h3 {
        color: white;
        margin: 0;
        font-size: 24px;
      

        @media (max-width: 768px) {
            font-size: 20px;  // Adjust font size for tablets
        }

        @media (max-width: 480px) {
            font-size: 19px;  // Adjust font size for mobile
        }
    }

 
     
}

.recent-client {
    white-space: nowrap;
    overflow: hidden;
    margin-top: 50px;
    position: relative;

    @media (max-width: 768px) {
        margin-top: 30px;  // Reduce margin for tablets
    }

    @media (max-width: 480px) {
        margin-top: 20px;  // Reduce margin for mobile
    }
}

.recent-client-head {
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 80px;
    color: #05a0e3;

    @media (max-width: 768px) {
        font-size: 32px;  // Adjust font size for tablets
        margin-bottom: 50px;  // Adjust margin for tablets
    }

    @media (max-width: 480px) {
        font-size: 28px;  // Adjust font size for mobile
        margin-bottom: 50px;  // Adjust margin for mobile
        margin-top: 22px;
        font-weight: 700;
    }
}

.recent-client-track {
    display: inline-block;
    white-space: nowrap;
    animation: slider 20s linear infinite;

    @media (max-width: 768px) {
        animation-duration: 15s;  // Adjust animation speed for tablets
    }

    @media (max-width: 480px) {
        animation-duration: 10s;  // Adjust animation speed for mobile
    }
}

.recent-client-track img {
    height: 100px;
    padding: 0 50px;
    margin-bottom: 80px;

    @media (max-width: 768px) {
        height: 80px;  // Adjust image size for tablets
        padding: 0 30px;  // Adjust padding for tablets
    }

    @media (max-width: 480px) {
        height: 60px;  // Adjust image size for mobile
        padding: 0 20px;  // Adjust padding for mobile
    }
}

.recent-client:hover .recent-client-track {
    animation-play-state: paused;
}

@keyframes slider {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
