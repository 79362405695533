.counters-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 0;
  margin-bottom: 80px;
  margin-top: 20px;

  .counter-box {
    text-align: center;
    color: rgb(0, 0, 0);

    h1 {
      font-size: 3rem;
      margin: 0;
    }

    p {
      margin: 10px 0 0;
      font-size: 1.2rem;
    }
  }

  // Responsive styles for mobile view
  @media (max-width: 768px) {
    flex-direction: column; /* Stack counters vertically */
    align-items: center;    /* Center the counters */
    padding: 20px 0;        /* Reduce padding in mobile view */
    margin-top: 10px;       /* Reduce top margin */
    margin-bottom: 40px;    /* Reduce bottom margin */

    .counter-box {
      margin-bottom: 50px;  /* Reduce spacing between counters */
    }
  }
}
