$mainColor : #05a0e3;

.portfolioView1 {
    // background:url('../Img/Background/wave5.svg');
    background-position: top 60% left 0%;
    background-size: 100%;
    position: relative;

    /*Intro*/
    .intro {
        position: relative;
        background: url('../Img/Background/construction-background.png') no-repeat;
        background-size: contain;
        background-position: center right;

        .text {
            max-width: 600px;
        }

        // #square{
        //     fill: none;
        //     stroke: #05a0e3;
        //     position: absolute;
        //     top:90%;
        //     right: 9%;
        //     width: 300px;
        //     stroke-width: 0.15;
        //     transform: rotate(40deg);
        //     z-index: -1;
        // }
    }

    /*Portfolio*/
    .portfolio-container {
        margin-top: 12em;

        .portfolio-item {
            h3 {
                margin-top: 2em;
                margin-bottom: .8em;
                margin-left: 5px;
                position: relative;
                z-index: 2;

                #square {
                    fill: none;
                    stroke: #05a0e3;
                    position: absolute;
                    stroke-width: 1;
                    top: -0.4em;
                    left: -0.8em;
                    width: 1.6em;
                    transform: rotate(20deg);
                    z-index: -1;
                }
            }

            p {
                max-width: 70%;
                margin-left: 20px;
                margin-top: 0;
                margin-bottom: 2.5em;
            }
        }
    }
}

/*Media queries*/
@media screen and (min-width:1550px) {
    .portfolioView {
        background: none !important;
    }
}

@media screen and (max-width:1100px) {
    .portfolioView {
        .intro {
            #square {
                width: 200px;
                top: 85%;
            }
        }

        .portfolio-container {
            margin-top: 3em;

            .portfolio-item {
                p {
                    max-width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width:830px) {
    .portfolioView {
        .intro {
            #square {
                width: 140px;
                top: 90%;
            }
        }

        .portfolio-container {
            .portfolio-item {
                h3 {
                    margin-bottom: .3em;
                }

                p {
                    margin-left: .5em;
                    margin-bottom: 1em;
                }
            }
        }
    }
}

@media screen and (max-width:550px) {
    .portfolioView {
        background: none;

        .intro {
            background: none;

            #square {
                width: 110px;
                top: 93%;
            }
        }
    }
}





// change
// Variables
$border-color: #ccc;
$background-color: #f9f9f9;
$content-background: #fff;
$hover-background-color: #e0f0ff; // Light blue color for hover
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$transition-duration: 0.3s;


// Center the dropdown on the screen with 75% width
.dropdown-container {
    width: 75%; // Width of dropdown container
    margin: 15px auto;
    text-align: center;
    //   border: 1px solid $border-color;
    border-radius: 5px;
    padding: 10px;
    // box-shadow: $box-shadow;
    // background-color: $background-color;
    cursor: pointer;
    transition: background-color $transition-duration ease;

    // &:hover {
    //     background-color: $hover-background-color; 
    // }

    // Style for the header of the dropdown
    .dropdown-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px; // Reduced padding for less thickness

        h2 {
            margin: 0;
            font-size: 1.2em; // Optional: Adjust font size if needed for better appearance
        }

        // Style for the dropdown arrow
        .dropdown-arrow {
            font-size: 24px; // Increased font size for a larger arrow
            transition: transform $transition-duration ease;

            // Rotate the arrow when the dropdown is open
            &.open {
                transform: rotate(180deg);
            }
        }
    }

    // Content that appears when the dropdown is open
    .dropdown-content {
        margin-top: 10px;
        padding: 10px;
        background-color: $content-background;
        border-top: 1px solid $border-color;
        box-shadow: $box-shadow;

        .dropdown-content-inner {
            display: flex;
            justify-content: space-between;
            align-items: flex-start; // Align items to the top
            flex-wrap: wrap; // Allow wrapping

            .content-left {
                flex: 2; // Occupy more space
                padding-right: 20px; // Add some space between content and image
                text-align: left; // Align text to the left

                ul {
                    list-style-type: disc; // Bullet points
                    padding-left: 20px; // Indentation for the list
                    margin: 0;
                }

                li {
                    margin-bottom: 5px; // Space between list items
                }
            }

            .content-right {
                flex: 1; // Occupy less space compared to the left content
                max-width: 150px; // Maximum width for the image
                text-align: right;

                img {
                    width: 100%; // Ensure the image fits within the container
                    height: auto;
                    display: block;
                    object-fit: cover;
                }
            }
        }
    }
}