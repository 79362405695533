$mainColor : #05a0e3;

.contactView {
    background: url('../Img/Background/excavator-background.png') no-repeat;
    background-position-y: 100%;
    background-position-x: 100%;
    background-size: 40%;
    position: relative;

    /*Contact form section*/
    .contactForm {
        position: relative;

        #square {
            top: 5%;
            right: 5%;
            width: 250px;
            transform: rotate(70deg);
            fill: none;
            stroke: $mainColor;
            stroke-width: .2;
            position: absolute;
            z-index: -1;
        }

        h2 {
            margin-bottom: 1.8em;
        }

        .wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .info {
                width: 35%;
                padding: 2em;
                min-width: 180px;
                max-width: 330px;
                background-color: $mainColor;
                color: white;
                border-radius: .5em;

                p {
                    line-height: 0.45em;
                    font-weight: 300;
                    margin-bottom: 0.4em;
                }

                .column {

                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        margin-top: 2em;
                    }

                    .heading {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 1.1em;

                        h4 {
                            margin-left: .5em;
                            margin-top: 0.1em;
                            margin-bottom: 0;
                            font-size: 1.1em;
                            font-weight: 500;
                        }
                    }
                }
            }

            .form {
                width: 40%;
                min-width: 300px;
                max-width: 500px;
                box-sizing: border-box;

                input,
                textarea {
                    padding: .8em;
                    font-size: 1em;
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                    border: 0.09em solid #ccc;
                    border-radius: .3em;
                    outline: none;
                    font-family: inherit;
                    -webkit-box-shadow: 0px 2px 7px -5px #000000;
                    box-shadow: 0px 2px 7px -5px #000000;
                    margin: 1em 0;
                }

                textarea {
                    height: 10em;
                }

                input:first-of-type {
                    margin-top: 0;
                }

                .send {
                    display: flex;
                    justify-content: center;
                    /* Center button horizontally */
                    margin-top: 20px;
                    /* Add some space above the button */

                    button {
                        display: flex;
                        align-items: center;
                        /* Align icon and text vertically */
                        background-color: $mainColor;
                        /* Button background color */
                        color: white;
                        /* Button text color */
                        border: none;
                        /* Remove default border */
                        padding: 10px 20px;
                        /* Adjust padding */
                        font-size: 1.2em;
                        /* Adjust font size */
                        border-radius: 5px;
                        /* Round the corners */
                        cursor: pointer;
                        /* Show pointer cursor on hover */
                        transition: background-color 0.3s ease;
                        /* Smooth background color change */
                        gap: 10px;
                        /* Space between the icon and text */

                        &:hover {
                            background-color: #124b63; /* Darker background color on hover */
                        }

                        FontAwesomeIcon {
                            font-size: 1.2em;
                            /* Adjust the icon size */
                        }
                    }
                }
            }
        }

        /* Success message */
        .success-message {
            background-color: white; 
            // color: #005000; 
            padding: 1em;
            // border: 1px solid black; 
            // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            background: transparent;
            border-radius: 0.5em;
            text-align: center;
            margin-top: 1em;
            display: block; 

            a {
                color: #007bff;
                text-decoration: none;
                font-weight: bold;
        
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .success-message h3{
            color: $mainColor;
        }.success-message p{
            color: black;
        }
    }

    /*Rental section*/
    .rental {
        width: 100%;
        margin: 0 auto;

        h2 {
            margin-bottom: 0.6em;
        }

        h1 {
            font-size: 5em;
            margin: 0;
            color: $mainColor;
            margin-left: 40px;
        }

        p {
            margin-left: 40px;
            font-size: 1.2em;
            font-weight: 400;
        }
    }
}

/*Media queries*/
@media screen and (min-width:1550px) {
    .contactView {
        background: none !important;
    }
}

@media screen and (max-width:1100px) {
    .contactView {
        .rental {
            h1 {
                font-size: 4em;
            }
        }
    }
}

@media screen and (max-width:830px) {
    .contactView {
        .contactForm {
            h2 {
                margin-bottom: 1em;
            }

            #square {
                top: 40%;
                right: -20%;
                width: 200px;
            }

            .wrapper {
                flex-direction: column;

                .info {
                    width: 100%;
                    max-width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 1em;
                    flex-direction: row;
                    justify-content: space-between;
                    box-sizing: border-box;

                    .column {
                        margin-top: 0 !important;
                        margin: 0.5em;
                    }
                }

                .form {
                    width: 100%;
                    margin-top: 2em;
                    max-width: 100%;
                }
            }
        }

        .rental {

            h1,
            p {
                margin-left: 0;
            }

            h1 {
                margin-bottom: 0;
            }

            p {
                margin: 0;
            }

            h3 {
                margin-bottom: 0.2em;
            }
        }
    }
}

@media screen and (max-width:575px) {
    .contactView {
        .rental {
            h1 {
                font-size: 2.95em;
            }
        }
    }
}

@media screen and (max-width:425px) {
    .contactView {
        .contactForm {
            .wrapper {
                .info {
                    flex-direction: column;

                    .column {
                        margin: 0.5em;
                    }

                    .column:nth-child(2) {
                        margin-top: .5em !important;
                        margin-bottom: 1em !important;
                    }
                }

                .form {
                    min-width: 100%;

                    .send {
                        width: 100%;
                        box-sizing: border-box;
                        text-align: center;
                        position: relative;

                        a::after {
                            display: none;
                        }
                    }
                }
            }
        }

        .rental {
            h1 {
                font-size: 2.5em;
            }
        }
    }
}

@media screen and (max-width:360px) {
    .contactView {
        .rental {
            h1 {
                font-size: 2em;
            }
        }
    }
}
